html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {margin: 0; padding: 0; border: 0; font-size: 100%; font: inherit;vertical-align: baseline;}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {display: block;}
body {line-height: 1;}
ol, ul {list-style: none;}
blockquote, q {quotes: none;}
blockquote:before, blockquote:after,q:before, q:after {content: '';content: none;}
table {border-collapse: collapse;border-spacing: 0;}
input:focus::-webkit-input-placeholder,textarea:focus::-webkit-input-placeholder{opacity: 0;}
input:focus,select:focus,textarea:focus{outline: none;}
button,input,textarea,select{font-family: Arial, Helvetica, sans-serif;}

.filebox:hover,.filebox input[type=file]:hover,.nice_checkbox:hover,button:hover{cursor:pointer;}

body{overflow-x:hidden;font-family: Arial, Helvetica, sans-serif;background-color:#232325;color: #dddce9;}
.wrap{padding:0 24px 30px 24px;margin:0 auto;width:510px;}
.inner{padding:0 24px;margin:0 auto;width:510px;}
.cl{clear:both;}

h1{padding:16px 0;font-size:24px;line-height:48px;font-weight:700;margin-bottom:11px;}
h1 .close{display:none;}

.sep{margin:11px 0 15px 0;border: 1px dashed rgba(60, 60, 67, 0.3);}

.user_info .vpn{background-repeat:no-repeat;background-position:left center;padding-left:32px;line-height:24px;color: #4CB482;font-weight: 700;font-size: 18px;margin-bottom:12px;}
.user_info p{line-height: 24px;font-size: 15px;}
.user_info b{font-weight: 700;}

.title{font-weight: 700;font-size: 18px;line-height: 32px;color:#007AFF;margin-bottom:19px;background-repeat:no-repeat;background-position:left center;}
.title small{color:#777777;font-weight: 400;font-size: 16px;}

.filebox{border: 0.8px dashed #D2D3D3;height:152px;line-height:152px;text-align:center;position:relative;color:#dddce9;font-size: 16px;margin-bottom:15px;}
.filebox .filebox-info{display:inline-block;line-height: 24px;}
.filebox input[type=file]{width:100%;height:152px;position:absolute;z-index:1;left:0;top:0;opacity:0;}
.filebox b{display:block;font-weight: 700;font-size: 20px;}
.filebox label{color:#1681EC;}

.upload_info{color:#777777;font-size: 15px;line-height: 24px;}
.upload_info ul li{list-style: disc inside;}

.description_input{resize: none;border: 1px solid #C5C5C7;border-radius: 8px;width:100%;height:50px;box-sizing:border-box;padding:12px 15px;font-size: 15px;line-height: 24px;color: #888888;}
.description_input::placeholder{color: #888888;}

/*.fixed{position:fixed;bottom:0;left:0;width:100%;background-color:#ffffff;z-index:2;}*/

.btn{display: block; cursor: pointer; width:100%;height:46px;line-height:46px;text-align:center;font-weight:700;font-size:16px;border-radius: 8px;border:1px #C5C5C7 solid;background-color:#ffffff;margin-bottom:24px;color: #000000;}
.btn.blue{color:#FFFFFF;background-color:#007AFF;border:1px #007AFF solid;}
.btn.green{color:#4CB482;background-color:#D6F4DE;border:1px #D6F4DE solid;font-weight:400;}
.btn.red{color:#FF2D55;background-color:#FFD5DD;border:1px #FFD5DD solid;font-weight:400;}

.btn > input[type="file"]{position: absolute; opacity: 0; width: 0; height: 0;}

.btn:disabled{background-color: #484848; border: 1px solid #484848}



.file_holder .file{padding:5px 0;}
.file_holder .file .num{font-size: 15px;line-height: 20px;text-transform: capitalize;color: #dddce9;margin-bottom:6px;}
.file_holder .file .name{display: flex; align-items: center; justify-content: space-between;  font-size: 16px;line-height: 24px;color: #dddce9;margin-bottom:6px;padding:3px 0;}
.file_holder .file .name.complete{color:#000000;}
.file_holder .file .name.error{color:#FF2D55;}
.file_holder .file .name .action{display:inline-block;width:24px;height:24px;vertical-align:middle;}

.file_holder .file .progressbar{background-color:#D6F4DE;border-radius: 2px;height:4px;width:100%;}
.file_holder .file .progressbar .progress{background-color:#34C759;border-radius: 2px;height:4px;}




.form input[type=text],.form input[type=password],.form select{width:100%;box-sizing:border-box;margin-bottom:15px;background: #FFFFFF;border: 1px solid #C5C5C7;border-radius: 8px;height:48px;line-height:48px;padding:0 15px;font-size: 15px;color: #888888;}
.form input[type=text]::placeholder,.form input[type=password]::placeholder{color: #888888;}
.form select{background-repeat:no-repeat;background-position:top 21px right 21px;-webkit-appearance: none;-moz-appearance: none;text-indent: 1px;text-overflow: '';}

.suggestion p{color:#FF2D55;margin-bottom:8px;line-height:20px;font-size:15px;}
.suggestion .btn{margin-bottom:10px;}

.row .col{float:left;box-sizing:border-box;padding:0 5px;}
.row .col.c-50{width:50%;}
.row .col.l{padding:0 5px 0 0;}
.row .col.r{padding:0 0 0 5px;}

.done_holder{padding:50px 24px 50px 24px;text-align:center;margin:0 auto;width:510px;}
.done_holder h2{font-weight:700;font-size:32px;line-height:48px;margin-bottom:16px;}
.done_holder h4{font-weight:600;font-size:22px;margin-bottom: 10px}
.done_holder p{font-size:15px;line-height:24px;margin-bottom:24px;}

.link{border: 1px solid #C5C5C7;border-radius: 8px;text-align:center;font-weight:700;font-size:12px;line-height:24px;margin-bottom:20px;color:#007AFF;padding:15px;word-break: break-all;}
.link > p{
    font-size: 12px;
    margin-bottom: 0;
    text-align: start;
}

.hide, .hide *{
    transition: 0.5s;
    height: 0;
    opacity: 0;
    margin: 0;
    pointer-events: none;
}

.nice_checkbox input[type="text"],.nice_checkbox input[type="password"]{opacity: 0; height: 0;width: 0; margin: 0; position: absolute}
.nice_checkbox{font-size:15px;line-height:24px;margin-bottom:15px; display: block}
.nice_checkbox input[type="checkbox"]{opacity:0; position: absolute; width: 0; height: 0}

.nice_checkbox input[type="checkbox"]:checked ~ input[type="text"]{display: block;}
.nice_checkbox input[type="checkbox"]:checked ~ input[type="password"]{display: block;}
.nice_checkbox .fake_checkbox{margin-bottom: 10px; height: 24px;width: 24px;display:inline-block;box-sizing:border-box;margin-right:5px;vertical-align:top;}

.nice_checkbox input[type="text"].show,.nice_checkbox input[type="password"].show{width:100%; transition:0.5s; opacity: 1; height: inherit; margin: inherit; position: initial}
.nice_checkbox.disabled  {text-decoration: line-through; color: #87878a;}

.AuthLabel{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;

}
.AuthLabel span{
    margin-left: 10px;
}
.AuthLabel input{
    margin-bottom: 0 !important;
}

*{
    box-sizing: border-box;
}
@media screen and (max-width: 560px) {
    .wrap,.inner,.done_holder{width:100%;box-sizing:border-box;}
}

@media screen and (max-width: 440px) {
    .hideOnMob{display: none}
    .filebox{display:none;}

    h1{font-size:23px;position:relative;padding-right:30px;}
    h1 .close{display:block;width:24px;height:24px;position:absolute;top:28px;right:0;}

    .user_info .vpn{line-height:32px;font-size: 20px;}

    .title{font-size: 20px;margin-bottom:12px;}
    .upload_info{margin-bottom:12px;}


    .file_holder .file{padding:11px 0;}
    .file_holder .file .name{font-size: 20px;}
}